import { SHOW_POP_UP } from './types';
import { deleteCampaign } from '../../components/Dashboard/clientHelper';

/**
 * popUpCode = 'addEmail' | 'removeEmail' | 'addWebsite' | 'removeWebsite' | 'addCampaign' | 'removeCampaign'
| 'textWithoutButtons' | 'addUserInEmailList' | 'editUserInEmailList'| 'removeSupplier'
| 'addSupplier' | 'tutorial' | 'ChooseHowToReportIncident' | 'noButtons'
 */
export const showPopUp =
  (popUpCode, item, preventBackgroundClose) => (dispatch) => {
    dispatch({
      type: SHOW_POP_UP,
      payload: { popUpCode, item, backgroundClosable: preventBackgroundClose },
    });
  };

/**
 *
 * @param {*} campaignId It removes a campaign from client's propery of phishing campaigns (no need to
 * dispatch action since firestore real-time will update redux)
 */
export const removePhishingCampaign = () => async (dispatch, getState) => {
  const { client, popUp, user } = getState();
  if (popUp.popUpCode === 'removeCampaign') {
    const { campaign_id, callback } = popUp.item;
    const saved = await deleteCampaign({ ...client, ...user }, campaign_id);
    // if it's saved succesfully close modal else close and show console error.
    if (!saved) {
      console.log('error while saving');
    }
    dispatch(showPopUp(null)); // todo show extra popUp for fails
    callback();
  }
};

export default true;
