import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Label from '../../atoms/Label/Label';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import SCLicensesSentByEmployeeTableCard from './LicensesSentByEmployeeTableCard.style';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';

const sumObjectValues = (object = {}) =>
  Object.values(object).reduce((acc, currentValue) => acc + currentValue, 0);

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
  ANDROID: 'android',
  IOS: 'ios',
};

const LicensesSentByEmployeeTableCard = () => {
  const [deviceFilter, setDeviceFilter] = useState([]);
  const dispatch = useDispatch();
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();

  const filteredTeamMembers =
    deviceFilter.length > 0
      ? teamMembers.filter((teamMember) => {
          return Object.entries(teamMember.licenses?.sent ?? {}).some(
            ([key, value]) => value && deviceFilter.includes(key)
          );
        })
      : teamMembers;

  const PAGE_SIZE = 10;

  const columns = [
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.name'),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 0,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.email'),
      options: {
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.licenses'),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  const getSetupToken = (token) => {
    return `${client.id}${token}`;
  };

  const mapperFn = (member) => {
    const {
      firstName,
      lastName,
      email,
      licenses = {},
      setupTokens = {},
    } = member;

    const sentLicenses = sumObjectValues(licenses.sent);
    const usedLicenses = sumObjectValues(licenses.used);

    return {
      name: {
        type: 'string',
        value: (
          <Tooltip
            tooltip={
              <p>
                {firstName} {lastName}
              </p>
            }>
            <p>
              {firstName} {lastName}
            </p>
          </Tooltip>
        ),
        sortValue: `${firstName} ${lastName}`,
      },
      email: {
        type: 'string',
        value: email,
        sortValue: email,
      },
      licenses: {
        type: 'component',
        value: (
          <Label
            value={
              sentLicenses
                ? i18n.t(
                    'deviceSecurity.selectEmailsModal.sentLicensesbyEmployee',
                    {
                      installed: usedLicenses,
                      total: sentLicenses,
                    }
                  )
                : '-'
            }
            width="130px"
            color={
              sentLicenses && usedLicenses !== sentLicenses ? 'red' : undefined
            }
          />
        ),
        sortValue: sentLicenses,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={member} cell />,
      },
      onClick:
        sentLicenses > 0
          ? () => {
              dispatch(
                showPopUp('licensesSentbyEmployee', {
                  name: `${firstName} ${lastName}`,
                  email,
                  licenses,
                  setupTokens: {
                    desktop: setupTokens.desktop
                      ? getSetupToken(setupTokens.desktop)
                      : undefined,
                    server: setupTokens.server
                      ? getSetupToken(setupTokens.server)
                      : undefined,
                  },
                })
              );
            }
          : undefined,
    };
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('deviceSecurity.selectEmailsModal.name'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { setSearch, setSearchTags, setSort, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    filteredTeamMembers,
    ['firstName', 'lastName', 'email'],
    sortOptions
  );

  return (
    <SCLicensesSentByEmployeeTableCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={i18n.t('deviceSecurity.sentLicensesbyEmployee.title')}>
        <TableContainer
          autoWidth={false}
          onChangeValue={setSearch}
          onChangeFilterTag={setSearchTags}
          columns={columns}
          rows={rows}
          hasRowIcon
          emptyMessage={i18n.t('manageEmailList.emptyMessage')}
          hasHorizontalScroll
          totalCount={length}
          sortOptions={sortOptions}
          pageSize={PAGE_SIZE}
          customSideElements={
            <Select
              mode="multiple"
              allowClear
              style={{ flex: 1, minWidth: '225px' }}
              placeholder={i18n.t(
                'deviceSecurity.sentLicensesbyEmployee.filterPlaceholder'
              )}
              defaultValue={[]}
              onChange={setDeviceFilter}
              options={[
                {
                  value: DEVICE_TYPES.DESKTOP,
                  label: i18n.t(
                    'deviceSecurity.sentLicensesbyEmployee.desktop'
                  ),
                },
                {
                  value: DEVICE_TYPES.SERVER,
                  label: i18n.t('deviceSecurity.sentLicensesbyEmployee.server'),
                },
                {
                  value: DEVICE_TYPES.ANDROID,
                  label: i18n.t(
                    'deviceSecurity.sentLicensesbyEmployee.android'
                  ),
                },
                {
                  value: DEVICE_TYPES.IOS,
                  label: i18n.t('deviceSecurity.sentLicensesbyEmployee.ios'),
                },
              ]}
            />
          }
        />
      </SectionCard>
    </SCLicensesSentByEmployeeTableCard>
  );
};

export default LicensesSentByEmployeeTableCard;
