import React from 'react';
import { useParams } from 'react-router-dom';
import SCPartnerClientControlPanel from './PartnerClientControlPanel.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import DeviceSecurityCard from '../../components/organisms/DeviceSecurityCard/DeviceSecurityCard';
import usePartnerClient from '../../hooks/usePartnerClient/usePartnerClient';
import BreachNotificationCard from '../../components/organisms/BreachNotificationCard/BreachNotificationCard';
import WebsiteSecurityCard from '../../components/organisms/WebsiteSecurityCard/WebsiteSecurityCard';
import EmailSecurityCard from '../../components/organisms/EmailSecurityCard/EmailSecurityCard';
import BackButton from '../../components/molecules/BackButton/BackButton';
import DomainImpersonationCard from '../../components/organisms/DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../../components/organisms/SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../../components/organisms/PhishingTrainingCard/PhishingTrainingCard';
import MySubscriptionCard from '../../components/organisms/PartnerClientMySubscriptionCard/MySubscriptionCard';
import PartnerClientControlPanelHeader from '../../components/molecules/PartnerClientControlPanelHeader/PartnerClientControlPanelHeader';

const PartnerClientControlPanel = () => {
  const { clientId } = useParams();
  const { partnerClient, loading } = usePartnerClient({ clientId });

  return (
    <SectionLayout>
      <SCPartnerClientControlPanel>
        <>
          <div className="section-back-button">
            <BackButton />
          </div>

          <div className="section-organism">
            <PartnerClientControlPanelHeader
              partnerClient={partnerClient}
              loading={loading}
            />
          </div>

          <div className="control-panel-cards-container">
            <MySubscriptionCard
              className="subscription-details-card"
              totalLicenses={
                partnerClient?.status?.serviceLevel?.limits?.nbLicences
              }
              installedLicenses={partnerClient?.status?.nbLicensesUsed}
              sentLicenses={partnerClient?.status?.nbLicensesSent}
              currentSubscriptionProduct={
                partnerClient?.status?.serviceLevelIDs[0]
              }
              currentSubscriptionExpirationDate={
                new Date(partnerClient?.status?.active)
              }
              subscriptionStartedAt={
                new Date(partnerClient?.status?.subscriptionStartedAt)
              }
              currentSubscriptionPeriodicity={
                partnerClient?.status?.subscriptionPeriodicity
              }
              loading={loading}
            />

            <DeviceSecurityCard
              data={partnerClient?.status?.deviceSecurity}
              loading={loading}
            />

            <BreachNotificationCard
              data={partnerClient?.status?.dataLeaks}
              loading={loading}
            />

            <EmailSecurityCard
              data={partnerClient?.status?.emailSecurity}
              atEmail={partnerClient?.atEmail}
              loading={loading}
            />

            <WebsiteSecurityCard
              data={{
                mainMonitoredDomain: partnerClient?.monitoredDomains?.[0],
                ...partnerClient?.status?.webSecurity,
              }}
              loading={loading}
            />

            <DomainImpersonationCard
              monitoredDomainsLength={partnerClient?.monitoredDomains?.length}
              data={partnerClient?.status?.webSpoofing}
              loading={loading}
            />

            <SupplierSecurityCard
              data={partnerClient?.status?.suppliersSecurity}
              loading={loading}
            />

            <PhishingTrainingCard
              data={partnerClient?.status?.phishingTraining}
              loading={loading}
            />
          </div>
        </>
      </SCPartnerClientControlPanel>
    </SectionLayout>
  );
};

export default PartnerClientControlPanel;
